@@ -1,76 +0,0 @@
<template>
  <v-card class="pa-8">
    <h1 class="text-center">ตั้งค่าการรับงาน</h1>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <span>ประเภทการบริการ</span>
        <v-radio-group v-model="serviceType" row>
          <v-radio label="PETNER NORMAL" value="PETNER NORMAL"></v-radio>
          <v-radio label="PETNER GOLD" value="PETNER GOLD"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <span>การรับบริการ</span>
        <v-radio-group v-model="serviceSubType" row>
          <v-radio label="อาบน้ำสุนัข" value="อาบน้ำสุนัข"></v-radio>
          <v-radio label="อาบน้ำแมว" value="อาบน้ำแมว"></v-radio>
          <v-radio label="อาบน้ำสุขัน & แมว" value="อาบน้ำสุขัน & แมว"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <span>จำนวนสัตว์เลี้ยงที่รับบริการ</span>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-text-field v-model="form.dogAmt" label="สุนัข" outlined dense hide-details="auto"
          type="number"></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-text-field v-model="form.catAmt" label="แมว" outlined dense hide-details="auto" type="number"></v-text-field>
      </v-col>

      <v-col cols="6" md="6" sm="12">
        <span>จังหวัดที่ให้บริการ</span>
        <v-autocomplete v-model="form.areaService" :items="provinces" item-value="name" item-text="name" @change="onChangeProvince"
          label="เลือกจังหวัด" outlined dense hide-details="auto"></v-autocomplete>
      </v-col>
      <v-col cols="6" md="6" sm="12">
        <span>เขตที่ให้บริการ</span>
        <v-autocomplete v-model="form.areaServiceDistrict" :items="districts" item-value="name" item-text="name"
          label="เลือกเขต" outlined dense hide-details="auto"></v-autocomplete>
      </v-col>

      <v-col cols="12" md="12" sm="12">
        <span>บริการ</span>
        <v-radio-group v-model="serviceBathOrCut" row>
          <v-radio label="อาบน้ำ" value="อาบน้ำ"></v-radio>
          <v-radio label="ตัดขน" value="ตัดขน"></v-radio>
          <v-radio label="อาบน้ำ & ตัดขน" value="อาบน้ำ & ตัดขน"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" sm="12">
        <span>น้ำหนักที่รับอาบ</span>
      </v-col>
      <v-col cols="5" md="5" sm="5">
        <v-text-field v-model="form.petWeightStart" outlined dense hide-details="auto" type="number"></v-text-field>
      </v-col>
      <span class="mt-3">ถึง</span>
      <v-col cols="5" md="5" sm="5">
        <v-text-field v-model="form.petWeightEnd" outlined dense hide-details="auto" type="number"></v-text-field>
      </v-col>
      <span class="mt-3">kg.</span>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" sm="12">
        <span>ประเภทขน</span>
        <v-radio-group v-model="petHair" row>
          <v-radio label="ขนสั้น" value="ขนสั้น"></v-radio>
          <v-radio label="ขนยาว" value="ขนยาว"></v-radio>
          <v-radio label="ขนสั้น & ขนยาว" value="ขนสั้น & ขนยาว"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" sm="12">
        <span>พฤติกรรมส่วนตัว
          <span style="font-size: 11px !important">
            หากสัตว์เลี้ยงดุให้ระบถตามความจริง กรณีสัตว์เลี้ยงดุ
            <span style="color: red; font-size: 10px !important">+100</span>
          </span></span>
        <v-radio-group v-model="petMood" row>
          <v-radio label="ดุ" value="ดุ"></v-radio>
          <v-radio label="ไม่ดุ" value="ไม่ดุ"></v-radio>
          <!-- <v-radio label="ไม่ดุ" value="3"></v-radio> -->
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" sm="12" class="mb-n5">
        <span>เวลาให้บริการ </span>
      </v-col>
      <v-col cols="3" md="3" sm="3">
        <v-checkbox v-model="form.isMonday">
          <template v-slot:label>
            <div>
              วันจันทร์
              <!-- <v-text-field v-model="form.serviceHairPrice" outlined dense placeholder="ราคา" type="number"></v-text-field> -->
            </div>
          </template>
        </v-checkbox>
      </v-col>

      <v-col cols="3" md="3" sm="3">
        <v-checkbox v-model="form.isTuesday">
          <template v-slot:label>
            <div>
              วันอังคาร
              <!-- <v-text-field v-model="form.serviceHairPrice" outlined dense placeholder="ราคา" type="number"></v-text-field> -->
            </div>
          </template>
        </v-checkbox>
      </v-col>

      <v-col cols="3" md="3" sm="3">
        <v-checkbox v-model="form.isWednesday">
          <template v-slot:label>
            <div>
              วันพุธ
              <!-- <v-text-field v-model="form.serviceHairPrice" outlined dense placeholder="ราคา" type="number"></v-text-field> -->
            </div>
          </template>
        </v-checkbox>
      </v-col>

      <v-col cols="3" md="3" sm="3">
        <v-checkbox v-model="form.isThursday">
          <template v-slot:label>
            <div>
              วันพฤหัสบดี
              <!-- <v-text-field v-model="form.serviceHairPrice" outlined dense placeholder="ราคา" type="number"></v-text-field> -->
            </div>
          </template>
        </v-checkbox>
      </v-col>

      <v-col cols="3" md="3" sm="3">
        <v-checkbox v-model="form.isFriday">
          <template v-slot:label>
            <div>
              วันศุกร์
              <!-- <v-text-field v-model="form.serviceHairPrice" outlined dense placeholder="ราคา" type="number"></v-text-field> -->
            </div>
          </template>
        </v-checkbox>
      </v-col>

      <v-col cols="3" md="3" sm="3">
        <v-checkbox v-model="form.isSaturday">
          <template v-slot:label>
            <div>
              วันเสาร์
              <!-- <v-text-field v-model="form.serviceHairPrice" outlined dense placeholder="ราคา" type="number"></v-text-field> -->
            </div>
          </template>
        </v-checkbox>
      </v-col>

      <v-col cols="3" md="3" sm="3">
        <v-checkbox v-model="form.isSunday">
          <template v-slot:label>
            <div>
              วันอาทิตย์
              <!-- <v-text-field v-model="form.serviceHairPrice" outlined dense placeholder="ราคา" type="number"></v-text-field> -->
            </div>
          </template>
        </v-checkbox>
      </v-col>

      
    </v-row>

    <v-row>
      <v-col cols="12" md="12" sm="12" class="mb-n5">
        <span>บริการเพิ่มเติม </span>
      </v-col>
      <v-col cols="6" md="6" sm="6">
        <v-checkbox v-model="form.serviceHair">
          <template v-slot:label>
            <div>
              สางขน
              <v-text-field v-model="form.serviceHairPrice" outlined dense placeholder="ราคา"
                type="number"></v-text-field>
            </div>
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="6" md="6" sm="6">
        <v-checkbox v-model="form.serviceTooth">
          <template v-slot:label>
            <div>
              แปรงฟัน<v-text-field v-model="form.serviceToothPrice" outlined dense placeholder="ราคา"
                type="number"></v-text-field>
            </div>
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="6" md="6" sm="6">
        <v-checkbox v-model="form.serviceCut">
          <template v-slot:label>
            <div>
              ไถเท้า/ท้อง/ก้น<v-text-field v-model="form.serviceCutPrice" outlined dense placeholder="ราคา"
                type="number"></v-text-field>
            </div>
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="6" md="6" sm="6">
        <v-checkbox v-model="form.serviceSpa">
          <template v-slot:label>
            <div>
              สปาเกลือ (บำรุงขน)<v-text-field v-model="form.serviceSpaPrice" outlined dense placeholder="ราคา"
                type="number"></v-text-field>
            </div>
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="6" md="6" sm="6">
        <v-checkbox v-model="form.serviceTom">
          <template v-slot:label>
            <div>
              บีบต่อม<v-text-field v-model="form.serviceTomPrice" outlined dense placeholder="ราคา"
                type="number"></v-text-field>
            </div>
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="6" md="6" sm="6">
        <v-checkbox v-model="form.serviceBath">
          <template v-slot:label>
            <div>
              อาบน้ำเชื้อรา<v-text-field v-model="form.serviceBathPrice" outlined dense placeholder="ราคา"
                type="number"></v-text-field>
            </div>
          </template></v-checkbox>
      </v-col>
      <v-col cols="6" md="6" sm="6">
        <v-checkbox v-model="form.serviceFinger">
          <template v-slot:label>
            <div>
              ตัดเล็บ<v-text-field v-model="form.serviceFingerPrice" outlined dense placeholder="ราคา"
                type="number"></v-text-field>
            </div>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-end">
        <v-btn color="primary" @click="submit()">save</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { Encode } from "@/services";
import { Decode } from "@/services";
export default {
  data() {
    return {
      serviceType: null,
      serviceSubType: null,
      petHair: null,
      petMood: null,
      serviceBathOrCut: null,
      form: {
        serviceHair: false,
        serviceTooth: false,
        serviceCut: false,
        serviceSpa: false,
        serviceTom: false,
        serviceBath: false,
        serviceFinger: false,
        serviceHairPrice: 0,
        serviceToothPrice: 0,
        serviceCutPrice: 0,
        serviceSpaPrice: 0,
        serviceTomPrice: 0,
        serviceBathPrice: 0,
        serviceFingerPrice: 0,
      },
      pic1: [],
      picture_1: "",
      showImage1: "",
      imageName1: "",
      pic2: [],
      picture_2: "",
      showImage2: "",
      imageName2: "",
      pic3: [],
      picture_3: "",
      showImage3: "",
      imageName3: "",
      pic4: [],
      picture_4: "",
      showImage4: "",
      imageName4: "",
      provinces: [],
      districts: []
    };
  },
  created() {
    // const checkuser = JSON.parse(
    //   Decode.decode(localStorage.getItem("DDLotAdmin"))
    // );
    // this.form = checkuser.user;
    this.getInfo();
    // this.serviceType = checkuser.user.serviceType;
    // this.serviceSubType = checkuser.user.serviceSubType;
    // this.serviceBathOrCut = checkuser.user.serviceBathOrCut;
    // this.petHair = checkuser.user.petHair;
    // this.petMood = checkuser.user.petMood;
    console.log("this.form", this.form);

    this.getAllProvince();
    this.getAllDistrict();
  },
  async mounted() { },
  methods: {
    async getInfo() {
      const checkuser = JSON.parse(
      Decode.decode(localStorage.getItem("DDLotAdmin"))
    );
    let response = {}
    if(!this.$router.currentRoute.query.id){
      response = await this.axios.get(
        `${process.env.VUE_APP_API}/partners/${checkuser.user.id}`
        // 'http://localhost:8081/categories/'
      );
    }else{
      response = await this.axios.get(
        `${process.env.VUE_APP_API}/partners/${this.$router.currentRoute.query.id}`
      );
    }
      console.log("get partner info", response.data);
      this.form = response.data.data;

      this.serviceType = this.form.serviceType;
      this.serviceSubType = this.form.serviceSubType;
      this.serviceBathOrCut = this.form.serviceBathOrCut;
      this.petHair = this.form.petHair;
      this.petMood = this.form.petMood;
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage1 = URL.createObjectURL(element);
        };
      }
    },
    showPicture2(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName2 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic2 = reader.result;
          this.showImage2 = URL.createObjectURL(element);
        };
      }
    },
    showPicture3(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName3 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic3 = reader.result;
          this.showImage3 = URL.createObjectURL(element);
        };
      }
    },
    showPicture4(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName4 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic4 = reader.result;
          this.showImage4 = URL.createObjectURL(element);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    changePic2() {
      document.getElementById("picTure2").click();
    },
    changePic3() {
      document.getElementById("picTure3").click();
    },
    changePic4() {
      document.getElementById("picTure4").click();
    },
    RemoveImage1(index, item) {
      this.pic1 = [];
      this.picture_1 = "";
      this.showImage1 = "";
      this.imageName1 = "";
    },
    RemoveImage2(index, item) {
      this.pic2 = [];
      this.picture_2 = "";
      this.showImage2 = "";
      this.imageName2 = "";
    },
    RemoveImage3(index, item) {
      this.pic3 = [];
      this.picture_3 = "";
      this.showImage3 = "";
      this.imageName3 = "";
    },
    RemoveImage4(index, item) {
      this.pic4 = [];
      this.picture_4 = "";
      this.showImage4 = "";
      this.imageName4 = "";
    },
    cancel() {
      this.$router.push("ManageDealer");
    },
    async getAllProvince() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/masters/findAllProvince`
      );
      console.log(response);

      if (response.data.response_status == "SUCCESS") {
        this.provinces = response.data.data;
      }

      // if(this.form.areaService){
      //   await this.onChangeProvince();
      //   this.form.areaServiceDistrice = this.form.areaServiceDistrice
      // }
    },
    async getAllDistrict() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/masters/findAllDistrict`
      );
      console.log(response);

      if (response.data.response_status == "SUCCESS") {
        this.districts = response.data.data;
      }

      // if(this.form.areaService){
      //   await this.onChangeProvince();
      //   this.form.areaServiceDistrice = this.form.areaServiceDistrice
      // }
    },
    async onChangeProvince(){
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/masters/findAllDistrictByProvince?provinceName=${this.form.areaService}`
      );
      console.log(response);

      if (response.data.response_status == "SUCCESS") {
        this.districts = response.data.data;
      }
    },
    async submit() {
      var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      console.log("userdata", user);
      const data = {
        ...this.form,
        serviceType: this.serviceType,
        serviceSubType: this.serviceSubType,
        serviceBathOrCut: this.serviceBathOrCut,
        petHair: this.petHair,
        petMood: this.petMood,
      };
      this.$swal({
        text: "คุณต้องการยืนยันบันทึกข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ยืนยันข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // var user = JSON.parse(
          //   Decode.decode(localStorage.getItem("DDLotAdmin"))
          // );
          // const auth = {
          //   headers: { Authorization: `Bearer ${user.token}` },
          // };
          const response = await this.axios.put(
            `${process.env.VUE_APP_API}/partners/${this.form.id}`,
            data,
            auth
          );
          console.log(response);

          if (response.data.response_status == "SUCCESS") {
            const responseUser = await this.axios.get(
              `${process.env.VUE_APP_API}/partners/${this.form.id}`
            );
            // localStorage.setItem(
            //   "DDLotAdmin",
            //   Encode.encode({ user: responseUser.data.data })
            // );

            this.$swal.fire({
              icon: "success",
              text: `บันทึกข้อมูลสำเร็จ`,
              showConfirmButton: false,
              timer: 1500,
            });
            // await location.reload();
            this.$router.go(0);
          }
        }
      });
    },
  },
};
</script>
